.footer-bar {
    background-color: #002b54;
    color: white;
    padding: 2rem 2rem;
    text-align: center;
}

.footer-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.footer-images img {
    width: 16.666%;
    height: auto;
    margin: 0rem;
}



.stay-updated {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    text-align: left;
}

.stay-updated h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.stay-updated p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.signup-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-form input {
    padding: 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px 0 0 4px;
    margin-right: 0rem;
    width: 300px;
}

.stay-updated .signup-form button {
    background-color: #002b54;
    padding: 0.5rem 1rem;
    border: 2px solid #fff;
    border-radius: 25px;
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
}

.stay-updated .signup-form button:hover {
    background-color: #fff;
    ;
    color: #002b54;
}

.separator-line {
    width: 80%;
    margin: 0 auto;
    /* Center it horizontally */
    border-bottom: 1px solid white;
    margin-bottom: 2rem;
}

.footer-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    text-align: left;
}

.support,
.contact {
    flex: 1;
}

.contact {
    text-align: right;
}

.logo {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    max-width: 50%;
    height: auto;
}

.support h3,
.contact h3 {
    margin-bottom: 1rem;
}

.support ul {
    list-style: none;
    padding: 0;
}

.support ul li {
    margin-bottom: 0.5rem;
}

.support ul li a {
    color: white;
    text-decoration: none;
}

.support ul li a:hover {
    text-decoration: underline;
}

.book-container {
    margin-top: 1rem;
    text-align: right;
}

.book-footer {
    padding: 0.5rem 1rem;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(26, 218, 113);
    border-radius: 25px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .stay-updated {
        flex-direction: column;
        text-align: center;
    }

    .stay-updated h2 {
        font-size: 2rem;
    }

    .signup-form {
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
    }

    .signup-form input {
        margin-right: 0;
        margin-bottom: 1rem;
        width: 100%;
    }

    .signup-form button {
        width: 100%;
    }

    .footer-info {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .support,
    .contact {
        text-align: center;
        margin-bottom: 1rem;
    }

    .contact {
        text-align: center;
    }
    .book-container {
        text-align: center;
    }
}

.footer-link {
    color: #fff;
    text-decoration: underline;
}

@media (max-width: 480px) {
    .footer-images {
        justify-content: center;
    }

    .footer-images img {
        width: 50%;
    }

    .logo img {
        max-width: 50%;
        height: auto;
    }
}